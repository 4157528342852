import { Flex, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { DisplayInformation, Dropdown } from 'components/elements/others';
import { IPOrderAnnotations } from 'pages/IPLife/components/orderAnnotations';
import { IPMeetingType } from 'services/hubApi';
import { IPostAnotations } from 'pages/IP/interfaces';
import { useQuery } from 'react-query';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import parser from 'html-react-parser';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { useEffect, useState } from 'react';
import { useUpdateIPMeeting } from 'pages/IPLife/hooks/useUpdateIPMeeting';
import { useHistory } from 'react-router-dom';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { CurrencyInput } from 'components/elements/forms/CurrencyInput';
import { debounce } from 'lodash';
import { IoMdClose } from 'react-icons/io';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { BaseModalFormCard } from 'components/elements/cards';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { IPMeetingDeclineModal } from '../meetingDecline';
import { IPMeetingScheduleModal } from '../meetingSchedule';
import { IPMeetingActionConfirmModal } from '../meetingActionConfirm';
import { IPMeetingBoletedModal } from '../meetingBoleted';
import { IPTransferOrderModal } from '../transferOrderModal';
import { IPUploadOrderFileModal } from '../uploadFile';
import { IPOrderFilesModal } from '../orderFiles';
import { IPMeetingGetStageBackModal } from '../meetingGetStageBackModal';

type PropsType = {
  meeting: IPMeetingType;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const IPMeetingDetailsModal: React.FC<PropsType> = ({
  meeting,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const { mutateAsync: updateMeeting, isLoading: isLoadingUpdateMeeting } =
    useUpdateIPMeeting();

  const history = useHistory();

  const correctMeetingDate = new Date(meeting.date);
  correctMeetingDate.setHours(correctMeetingDate.getHours() + 3);

  const [newMeetingStatus, setNewMeetingStatus] = useState<string>('');
  const [
    shouldShowAnnualizedPremiumInput,
    setShouldShowAnnualizedPremiumInput,
  ] = useState<boolean>(false);

  const [annualizedPremium, setAnnualizedPremium] = useState<
    string | undefined
  >(meeting?.order?.annualizedPremium?.toString() ?? '');

  const [isOpenMeetingConfirmModal, setIsOpenMeetingConfirmModal] =
    useState<boolean>(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState<string>('');
  const [confirmModalDescription, setConfirmModalDescription] =
    useState<string>('');

  const [isOpenMeetingDeclineModal, setIsOpenMeetingDeclineModal] =
    useState<boolean>(false);
  const [isOpenMeetingScheduleModal, setIsOpenMeetingScheduleModal] =
    useState<boolean>(false);

  const [isOpenMeetingStageBackModal, setIsOpenMeetingStageBackModal] =
    useState<boolean>(false);

  const [isOpenMeetingBoletedModal, setIsOpenMeetingBoletedModal] =
    useState<boolean>(false);

  const [isOpenIPTransferOrderModal, setIsOpenIPTransferOrderModal] =
    useState<boolean>(false);

  const [isOpenUploadFileModal, setIsOpenUploadFileModal] =
    useState<boolean>(false);

  const [isOpenOrderFilesModal, setIsOpenOrderFilesModal] =
    useState<boolean>(false);

  // const { data: clientInfos } = useQuery({
  //   queryKey: ['clientInfos', meeting?.order?.clientCode],
  //   queryFn: async () => {
  //     const response = await hubApiClients.getAllClients({
  //       filterValue: meeting.order.clientCode as string,
  //     });
  //     return response[0];
  //   },
  //   enabled: !!meeting?.order?.clientCode,
  // });

  const { data: orderNotes } = useQuery<IPostAnotations[]>({
    queryKey: ['orderNotes', meeting?.order?.id],
    queryFn: async () => {
      const clientNotes = await hubApiUser.getClientNotes(meeting.order.id);

      return clientNotes;
    },
  });

  // const { data: orderFiles } = useQuery({
  //   queryKey: ['orderFiles', clientInfos?.btgCode],
  //   queryFn: async () => {
  //     const response = await hubApiIp.getFilesByClientId(clientInfos.btgCode);
  //     return response;
  //   },
  //   enabled: !!clientInfos?.btgCode,
  // });

  const sendToClientPanel = () => {
    window.sessionStorage.setItem(
      'searchClientContext',
      meeting.order.clientCode as string
    );

    history.push(
      `/user/users_clients/new/${meeting.order.clientCode as string}`
    );
  };

  const handleChangeMeetingDate = async (date: string) => {
    await updateMeeting({
      meetingId: meeting.id,
      date,
    });
  };

  useEffect(() => {
    if (
      Number(annualizedPremium?.replaceAll(',', '.')) ===
        meeting.order.annualizedPremium ||
      Number(annualizedPremium?.replaceAll(',', '.')) <= 0
    ) {
      return;
    }

    const handleDebounced = debounce(() => {
      updateMeeting({
        meetingId: meeting.id,
        annualizedPremium: Number(annualizedPremium?.replaceAll(',', '.')),
      });
    }, 500);

    handleDebounced();

    return () => {
      handleDebounced.cancel();
    };
  }, [annualizedPremium]);

  if (!meeting) {
    return <></>;
  }

  return (
    <DefaultModalContentWrapper maxW="90%" overflowY="auto">
      <Flex justifyContent="space-between" alignItems="center">
        <ModalTitle>Dados da Ordem - IP</ModalTitle>
        <IoMdClose
          cursor="pointer"
          size={30}
          onClick={() => handleToggleOpenMeetingDetailsModal(false)}
        />
      </Flex>
      <Flex gap={3}>
        <Flex flexDirection="column" w="33%" gap={4}>
          <Flex flexDirection="column" gap={2}>
            <DisplayInformation
              label="Cliente"
              info={meeting.order.name.toUpperCase()}
              isClient={meeting.order.isClient}
              leadId={meeting.order.leadId}
              leadType={meeting.order.leadType}
            />

            <DisplayInformation
              label="Nome do Assessor"
              info={formatNameToCaptalize(meeting.order.employee.name)}
            />

            {meeting.order.phone && (
              <DisplayInformation label="Telefone" info={meeting.order.phone} />
            )}

            {meeting.order.email && (
              <DisplayInformation label="E-mail" info={meeting.order.email} />
            )}

            {meeting.order.information && (
              <DisplayInformation
                label="Informações"
                info={meeting.order.information}
              />
            )}

            {meeting.order.created_at && (
              <DisplayInformation
                label="Data da Abertura"
                info={`Aberta em ${formateDateAndTime(
                  meeting.order.created_at
                )}`}
              />
            )}

            {meeting.date && (
              <DisplayInformation
                label={`Data da reunião - ${formateDateAndTime(
                  correctMeetingDate.toISOString()
                )}`}
                info={
                  <DateAndHourPicker
                    onChange={date => handleChangeMeetingDate(date.toString())}
                  />
                }
              />
            )}

            <DisplayInformation
              label="Prêmio Anualizado"
              info={
                <CurrencyInput
                  value={annualizedPremium}
                  onValueChange={value => setAnnualizedPremium(value)}
                />
              }
            />
          </Flex>

          <Flex gap={2}>
            <SecondaryButton
              size="xs"
              w="50%"
              onClick={() => setIsOpenIPTransferOrderModal(true)}
            >
              Transferir Ordem
            </SecondaryButton>
            {meeting.order.isClient && (
              <SecondaryButton w="50%" onClick={sendToClientPanel} size="xs">
                Ver painel do cliente
              </SecondaryButton>
            )}
          </Flex>
          <Flex gap={2}>
            {meeting.order.isClient && (
              <SecondaryButton
                w="50%"
                onClick={() => setIsOpenUploadFileModal(true)}
                size="xs"
              >
                Fazer upload de arquivo
              </SecondaryButton>
            )}
            {/* {meeting.order.isClient && orderFiles && orderFiles.length > 0 && (
              <SecondaryButton
                w="50%"
                size="xs"
                onClick={() => setIsOpenOrderFilesModal(true)}
              >
                Ver Arquivos da Ordem
              </SecondaryButton>
            )} */}
          </Flex>
        </Flex>
        <DisplayInformation
          w={{ base: 'full', md: '33%' }}
          label="Anotações"
          info={<IPOrderAnnotations order={meeting.order} />}
          borderBottom="none"
        />
        <DisplayInformation
          w={{ base: 'full', md: '33%' }}
          label="Anotações anteriores"
          borderBottom="none"
          info={
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              h="100%"
            >
              <Stack
                overflowY="scroll"
                w="full"
                className="slimScrollbar"
                maxH="400px"
              >
                <UnorderedList spacing={2}>
                  {orderNotes &&
                    orderNotes.map(note => (
                      <ListItem key={note.orderId}>
                        <Flex gap={2}>
                          {note.notes && (
                            <Text wordBreak="break-all">
                              <Text fontStyle="italic">
                                (Nota No Painel Do Cliente)
                              </Text>
                              {parser(note.notes)}
                            </Text>
                          )}
                        </Flex>
                        {!note.notes && parser(note.msg)}
                      </ListItem>
                    ))}
                </UnorderedList>
              </Stack>
            </Flex>
          }
        />
      </Flex>
      <Flex justifyContent="flex-end" gap={2}>
        {meeting.status === 'noContact' && (
          <Flex gap={2}>
            <SecondaryButton
              onClick={() => {
                setConfirmModalTitle('Tentativa de Contato');
                setConfirmModalDescription(
                  'Você confirma que houve uma tentativa de contato sem êxito com este cliente?'
                );
                setNewMeetingStatus('contactAttempt');
                setIsOpenMeetingConfirmModal(true);
              }}
            >
              Tentativa de contato
            </SecondaryButton>
            <SecondaryButton onClick={() => setIsOpenMeetingDeclineModal(true)}>
              Declínio
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setNewMeetingStatus('callDialed');
                setIsOpenMeetingScheduleModal(true);
              }}
            >
              Agendar Reunião
            </PrimaryButton>
          </Flex>
        )}

        {meeting.status === 'contactAttempt' && (
          <Flex gap={2}>
            <SecondaryButton onClick={() => setIsOpenMeetingDeclineModal(true)}>
              Declínio
            </SecondaryButton>
            <SecondaryButton
              onClick={() => setIsOpenMeetingStageBackModal(true)}
            >
              Voltar Etapa
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setNewMeetingStatus('callDialed');
                setIsOpenMeetingScheduleModal(true);
              }}
            >
              Agendar Reunião
            </PrimaryButton>
          </Flex>
        )}
        {meeting.status === 'callDialed' && (
          <Flex gap={2}>
            <SecondaryButton onClick={() => setIsOpenMeetingDeclineModal(true)}>
              Declínio
            </SecondaryButton>
            <SecondaryButton
              onClick={() => setIsOpenMeetingStageBackModal(true)}
            >
              Voltar Etapa
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setNewMeetingStatus('studying');
                setIsOpenMeetingScheduleModal(true);
              }}
            >
              Agendar Reunião
            </PrimaryButton>
          </Flex>
        )}
        {meeting.status === 'studying' && (
          <Flex gap={2}>
            <SecondaryButton onClick={() => setIsOpenMeetingDeclineModal(true)}>
              Declínio
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setShouldShowAnnualizedPremiumInput(true);
                setNewMeetingStatus('waitingPresentation');
                setIsOpenMeetingScheduleModal(true);
              }}
            >
              Agendar Reunião
            </PrimaryButton>
          </Flex>
        )}
        {meeting.status === 'waitingPresentation' && (
          <Flex gap={2}>
            <SecondaryButton onClick={() => setIsOpenMeetingDeclineModal(true)}>
              Declínio
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setConfirmModalTitle('Apresentação Realizada');
                setConfirmModalDescription(
                  'Você confirma que realizou a apresentação para o cliente?'
                );
                setNewMeetingStatus('presentationRealized');
                setIsOpenMeetingConfirmModal(true);
              }}
            >
              Apresentação Realizada
            </PrimaryButton>
          </Flex>
        )}
        {meeting.status === 'presentationRealized' && (
          <Flex gap={2}>
            <SecondaryButton onClick={() => setIsOpenMeetingDeclineModal(true)}>
              Declínio
            </SecondaryButton>
            <PrimaryButton onClick={() => setIsOpenMeetingBoletedModal(true)}>
              Boletado
            </PrimaryButton>
          </Flex>
        )}
        {meeting.status === 'boleted' && (
          <Flex gap={2}>
            <PrimaryButton
              onClick={() => {
                setConfirmModalTitle('Retirar do CRM');
                setConfirmModalDescription('Deseja retirar essa ordem do CRM?');
                setNewMeetingStatus('boletedNoShow');
                setIsOpenMeetingConfirmModal(true);
              }}
            >
              Retirar do CRM
            </PrimaryButton>
          </Flex>
        )}
      </Flex>
      <ModalWrapper
        isOpen={isOpenMeetingConfirmModal}
        onClose={() => setIsOpenMeetingConfirmModal(false)}
      >
        <IPMeetingActionConfirmModal
          title={confirmModalTitle}
          description={confirmModalDescription}
          setIsOpenMeetingConfirmModal={setIsOpenMeetingConfirmModal}
          isLoadingUpdateMeeting={isLoadingUpdateMeeting}
          submitFunction={() =>
            updateMeeting({
              meetingId: meeting.id,
              status: newMeetingStatus,
            })
          }
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenMeetingDeclineModal}
        onClose={() => setIsOpenMeetingDeclineModal(false)}
      >
        <IPMeetingDeclineModal
          meeting={meeting}
          setIsOpenMeetingDeclineModal={setIsOpenMeetingDeclineModal}
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenMeetingScheduleModal}
        onClose={() => setIsOpenMeetingScheduleModal(false)}
      >
        <IPMeetingScheduleModal
          meeting={meeting}
          shouldShowAnnualizedPremiumInput={shouldShowAnnualizedPremiumInput}
          newMeetingStatus={newMeetingStatus}
          setIsOpenMeetingScheduleModal={setIsOpenMeetingScheduleModal}
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenMeetingStageBackModal}
        onClose={() => setIsOpenMeetingStageBackModal(false)}
      >
        <IPMeetingGetStageBackModal
          meeting={meeting}
          setIsOpenMeetingStageBackModal={setIsOpenMeetingStageBackModal}
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenMeetingBoletedModal}
        onClose={() => setIsOpenMeetingBoletedModal(false)}
      >
        <IPMeetingBoletedModal
          meeting={meeting}
          setIsOpenMeetingBoletedModal={setIsOpenMeetingBoletedModal}
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenIPTransferOrderModal}
        onClose={() => setIsOpenIPTransferOrderModal(false)}
      >
        <IPTransferOrderModal
          order={meeting.order}
          setIsOpenIPTransferOrderModal={setIsOpenIPTransferOrderModal}
        />
      </ModalWrapper>

      {/* <ModalWrapper
        isOpen={isOpenOrderFilesModal}
        onClose={() => setIsOpenOrderFilesModal(false)}
      >
        <IPOrderFilesModal
          setIsOpenOrderFilesModal={setIsOpenOrderFilesModal}
          orderFiles={orderFiles}
        />
      </ModalWrapper> */}

      {/* {clientInfos?.id && (
        <ModalWrapper
          isOpen={isOpenUploadFileModal}
          onClose={() => setIsOpenUploadFileModal(false)}
        >
          <IPUploadOrderFileModal
            order={meeting.order}
            clientId={clientInfos.id}
            setIsOpenUploadFileModal={setIsOpenUploadFileModal}
          />
        </ModalWrapper>
      )} */}
    </DefaultModalContentWrapper>
  );
};
