import { Flex, Text } from '@chakra-ui/react';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { Kanban, SortAscending, SortDescending } from 'phosphor-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  GetMeetingsPoolResponseType,
  hubApiCaptation,
} from 'services/hubApi/classes/HubApiCaptation';
import { colors } from 'sharedV2/colors';
import { Icons } from 'sharedV2/icons';
import { CurrencyUtils } from 'utilsV2/Currency';
import { MeetingsPoolHeader } from './components/header';
import { MeetingsPoolMosaic } from './components/mosaic';
import { MeetingsPoolMosaicCard } from './components/mosaicCard';
import { MeetingsPoolSkekeletonLoading } from './components/skeletonLoading';
import { MeetingsPoolTabs } from './components/tabs';

const columns: { title: string; status: keyof GetMeetingsPoolResponseType }[] =
  [
    {
      title: 'Retornos',
      status: 'callback',
    },
    {
      title: 'R1 - Suitability',
      status: 'suitability',
    },
    {
      title: 'R2 - Carteira',
      status: 'presentation',
    },
    {
      title: 'Ag. Abertura',
      status: 'allocation',
    },
    {
      title: 'Aporte',
      status: 'account',
    },
  ];

export const MeetingsPool = (): JSX.Element => {
  const [visualizationMode, setVisualizationMode] = useState<string>('kanban');

  const { data: meetings, isLoading: isLoadingMeetingsPool } = useQuery({
    queryKey: ['meetingsPool'],
    queryFn: async () => {
      const response = await hubApiCaptation.getMeetingsPool();
      return response;
    },
  });

  if (isLoadingMeetingsPool) {
    return <MeetingsPoolSkekeletonLoading />;
  }

  return (
    <PageWrapper alignItems="center" paddingTop="2rem">
      <Flex w="95vw" justifyContent="end" mt="-25px">
        <Kanban
          size={38}
          color={visualizationMode === 'kanban' ? colors.BLUE : '#b0b0b0'}
          onClick={() => setVisualizationMode('kanban')}
          cursor="pointer"
        />
        <Icons.GRID
          size={38}
          color={visualizationMode === 'mosaic' ? colors.BLUE : '#b0b0b0'}
          onClick={() => setVisualizationMode('mosaic')}
        />
      </Flex>

      {visualizationMode === 'mosaic' && (
        <Flex w="95vw" h="calc(100vh - 150px)" gap={2}>
          <MeetingsPoolTabs meetings={meetings} />
          <Flex w="100%" flexDirection="column" gap={2}>
            {/* <MeetingsPoolHeader /> */}

            <MeetingsPoolMosaic
              meetings={meetings}
              visualizationMode={visualizationMode}
            />
          </Flex>
        </Flex>
      )}

      {visualizationMode === 'kanban' && (
        <Flex gap={2} w="95vw">
          {columns.map(item => (
            <Column
              item={item}
              meetings={meetings}
              visualizationMode={visualizationMode}
            />
          ))}
        </Flex>
      )}
    </PageWrapper>
  );
};

const Column: React.FC<{
  item: {
    title: string;
    status: keyof GetMeetingsPoolResponseType;
  };
  meetings: GetMeetingsPoolResponseType | undefined;
  visualizationMode: string;
}> = ({ item, meetings, visualizationMode }): JSX.Element => {
  const [sortMeetingDate, setSortMeetingDate] = useState<'ASC' | 'DESC'>('ASC');

  const handleGetMeetingsAndSort = (
    status: keyof GetMeetingsPoolResponseType
  ) => {
    if (!meetings) {
      return [];
    }

    const sortedMeetings = meetings[status].meetings.slice().sort((a, b) => {
      if (sortMeetingDate === 'ASC') {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }

      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

    return sortedMeetings;
  };

  const meetingsFiltered = handleGetMeetingsAndSort(item.status);

  return (
    <Flex flexDirection="column" w="100%" minW="10%">
      <Text
        color="rgba(70, 70, 70, 1)"
        fontSize={17}
        fontWeight="bold"
        marginLeft="2px"
        marginBottom="4px"
      >
        {item.title} ({meetingsFiltered && meetingsFiltered.length})
      </Text>
      <Flex
        bgColor="white"
        h="calc(100vh - 180px)"
        w="100%"
        borderRadius={5}
        p={3}
        overflowY="auto"
        flexDirection="column"
        className="thinScrollbar"
        gap={3}
        overflowX="hidden"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
            {meetings && CurrencyUtils.format(meetings[item.status].pipeValue)}
          </Text>

          {sortMeetingDate === 'ASC' ? (
            <SortAscending
              size={28}
              cursor="pointer"
              color="rgba(70, 70, 70, 1)"
              onClick={() => setSortMeetingDate('DESC')}
            />
          ) : (
            <SortDescending
              size={28}
              cursor="pointer"
              color="rgba(70, 70, 70, 1)"
              onClick={() => setSortMeetingDate('ASC')}
            />
          )}
        </Flex>
        {meetingsFiltered.map((meeting, index) => (
          <MeetingsPoolMosaicCard
            key={index}
            meeting={meeting}
            visualizationMode={visualizationMode}
            isInLateSection={false} // Deprecated
          />
        ))}
      </Flex>
    </Flex>
  );
};
