import { Flex, Text } from '@chakra-ui/react';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { ModalWrapper } from 'components/elements/wrappers';
import { Menu } from 'componentsV2/elements/menu';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import {
  BasicForm,
  RealEstateAndFleetForm,
} from 'pages/IP/components/modules/forms';
import { ipProducts } from 'pages/IP/data';
import { useQueryParams } from 'hooksV2/useQueryParams';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { useHistory } from 'react-router-dom';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { LeadMundoInvestStatusEnum } from 'sharedV2/types/leadMundoInvest';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { LeadDetailsDeclineOffer } from '../declineOffer';
import { LeadDetailsAllocated } from '../leadAllocated';
import { LeadDetailsAnswer } from '../leadAnswer';
import { LeadContactAttempts } from '../leadContactAttempts';
import { LeadDetailsNoAnswer } from '../noAnswer';
import { LeadDetailsOpenAccount } from '../openAccount';
import { LeadDetailsRecoverMeetingFromPipe } from '../recoverMeetingFromPipe';
import { LeadDetailsRouteMeeeting } from '../routeMeeting';
import { LeadDetailsScheduleMeeeting } from '../scheduleMeeting';
import { ScheduleMeetingCallback } from '../scheduleMeetingCallback';
import { LeadDetailsTransferLeadToAAI } from '../transferLeadToAAI';
import { LeadDetailsTransferLeadToSalesHunter } from '../transferLeadToSalesHunter';

export const LeadDetailsFooter = (): JSX.Element => {
  const history = useHistory();
  const { lead, leadType, desiredFlow } = useLeadDetailsContext();
  const { getParam } = useQueryParams();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalAp, setShowModalAp] = useState<boolean>(false);

  const handleToggleModal = () => setShowModal(!showModal);
  const handleToggleModalAP = () => setShowModalAp(!showModalAp);

  const { goBack } = useNavigation();

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const isFromProspectPage = getParam('isFromProspectPage') === 'true';

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  const isLeadsSearchDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.LEADS_SEARCH;

  const isLeadWithoutFeeback =
    lead?.meeting?.confirm === false &&
    !isFromProspectPage &&
    // (lead?.meeting?.leadType === LeadTypeEnum.MUNDO_INVEST ||
    //   lead?.meeting?.leadType === 'publics') &&
    lead?.meeting?.complement !==
      'Lead abriu conta no Mundo Invest pelo seu link.';

  const { data: ipOrders } = useQuery<IPOrderType[]>({
    queryKey: ['ipOrders', lead.id],
    queryFn: async () => {
      const response = await hubApiIp.getOrderIpByLeadId(lead.id);
      return response;
    },
  });

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions();

  const { mutateAsync: updateLead, isLoading: isLoadingUpdateLead } =
    useUpdateLead();

  const isLoading = isLoadingUpdateLead || isLoadingMeetingAction;

  const handleTakeOffLeadAction = async () => {
    if (lead.meeting) {
      await meetingAction({
        action: MeetingActionEnum.DECLINED,
        leadType,
        meetingId: lead?.meeting?.id as string,
        declinedReason: 'PJ2',
      });

      return goBack();
    }

    await updateLead({
      type: leadType,
      leadId: lead.id,
      status: LeadMundoInvestStatusEnum.FINALIZADO,
      declinedReason: 'PJ2',
    });

    goBack();
  };

  const thereIsAIpOrderActive =
    ipOrders &&
    ipOrders.filter(
      order => order.finished === false && order.segment === 'vida'
    ).length > 0;

  const thereIsAAPOrderActive =
    ipOrders &&
    ipOrders.filter(
      order => order.finished === false && order.segment === 'imobiliario'
    ).length > 0;

  const renderButtonsByMeetingStatus = () => {
    if (isLeadsSearchDesiredFlow) {
      return (
        <>
          {leadBussinessLogic.isLeadAbleToBeTransfferedToASalesHunter() && (
            <LeadDetailsTransferLeadToSalesHunter />
          )}

          <LeadDetailsTransferLeadToAAI />
          <LeadDetailsDeclineOffer />
        </>
      );
    }

    if (!lead.meeting) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsScheduleMeeeting />
        </>
      );
    }

    if (lead.meeting.status === MeetingStatusEnum.CREATED) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsRouteMeeeting />
        </>
      );
    }

    if (lead.meeting.status === MeetingStatusEnum.RETURNED) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsScheduleMeeeting />
        </>
      );
    }

    if (lead.meeting.status === MeetingStatusEnum.CALLBACK) {
      return (
        <>
          <LeadDetailsDeclineOffer />
          <ScheduleMeetingCallback />
          <LeadDetailsScheduleMeeeting />
        </>
      );
    }

    if (isLeadWithoutFeeback) {
      return (
        <>
          <LeadDetailsNoAnswer />
          <LeadDetailsAnswer />
        </>
      );
    }

    return (
      <>
        {(lead?.meeting?.confirm || leadType !== LeadTypeEnum.MUNDO_INVEST) && (
          <>
            {lead?.meeting?.status === MeetingStatusEnum.ACCOUNT ? (
              <LeadDetailsAllocated />
            ) : (
              <>
                {lead?.meeting?.status !== MeetingStatusEnum.DECLINED && (
                  <LeadDetailsDeclineOffer />
                )}

                {!lead?.meeting?.isPipe && <ScheduleMeetingCallback />}

                {lead?.meeting && <LeadDetailsOpenAccount />}

                {lead?.meeting?.status !== MeetingStatusEnum.ALLOCATION && (
                  <>
                    {lead?.meeting?.isPipe ||
                    lead.meeting.status === MeetingStatusEnum.DECLINED ? (
                      <LeadDetailsRecoverMeetingFromPipe />
                    ) : (
                      <LeadDetailsScheduleMeeeting />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Flex
      w="100%"
      justifyContent={!isSalesHunterDesiredFlow ? 'space-between' : 'flex-end'}
    >
      {!isSalesHunterDesiredFlow && <LeadContactAttempts />}

      <Flex gap={2}>
        {!isSalesHunterDesiredFlow &&
          (!thereIsAAPOrderActive || !thereIsAIpOrderActive) && (
            <Menu.Root>
              <Menu.Button
                background="white"
                color="rgba(70, 70, 70, 1)"
                border="1px solid #1d63ddb2"
                fontWeight="semibold"
                py={1}
                px={3}
                _hover={{ bg: '#1d63ddd5', color: 'white' }}
              >
                <Text fontSize="0.8rem">Indicação Ilha Forte</Text>
              </Menu.Button>
              <Menu.List>
                {!thereIsAIpOrderActive && (
                  <Menu.Item onClick={() => setShowModal(true)}>
                    Inteligência Patrimonial
                  </Menu.Item>
                )}

                {!thereIsAAPOrderActive && (
                  <Menu.Item onClick={() => setShowModalAp(true)}>
                    Alavancagem Patrimonial
                  </Menu.Item>
                )}
              </Menu.List>
            </Menu.Root>
          )}

        <Flex gap={2}>{renderButtonsByMeetingStatus()}</Flex>
      </Flex>

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BasicForm
          isLoadingTakeOffLead={isLoading}
          handleShowModal={handleToggleModal}
          title={ipProducts[0].title}
          segment={ipProducts[0].segment || ''}
          description={ipProducts[0].description || ''}
          initialFormData={{
            email: lead.email as string,
            name: lead.name,
            phone: lead.phone || (lead.phones?.at(0) as string),
          }}
          leadId={lead.id}
          leadType={leadType}
          takeOffLeadAction={handleTakeOffLeadAction}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={showModalAp} onClose={handleToggleModalAP}>
        <RealEstateAndFleetForm
          isLoadingTakeOffLead={isLoading}
          takeOffLeadAction={handleTakeOffLeadAction}
          handleShowModal={handleToggleModalAP}
          title={ipProducts[2].title}
          segment={ipProducts[2].segment || ''}
          description={ipProducts[2].description || ''}
          initialFormData={{
            email: lead.email as string,
            name: lead.name,
            phone: lead.phone || (lead.phones?.at(2) as string),
          }}
          leadType={leadType}
          leadId={lead.id}
        />
      </ModalWrapper>
    </Flex>
  );
};
