import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { formatToPhone } from 'brazilian-values';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { IPostOrderIp } from 'pages/IP/interfaces';
import { handleBlurCLient } from 'pages/IP/utils';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { Dropdown } from 'components/elements/others';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { queryClient } from 'App';

interface BasicFormProps {
  handleShowModal: () => void;
  title: string;
  segment: string;
  subSegment?: string;
  description: string;
  initialFormData?: {
    name: string;
    email: string;
    phone: string;
  };
  leadId?: string;
  leadType?: string;
  takeOffLeadAction?: () => void;
  isLoadingTakeOffLead?: boolean;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  handleShowModal,
  title,
  segment,
  subSegment,
  description,
  initialFormData,
  leadId,
  leadType,
  takeOffLeadAction,
  isLoadingTakeOffLead,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [openTakeOffLead, setOpenTakeOffLead] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSecondaryButton, setIsLoadingSecondaryButton] =
    useState(false);
  const [openLifeSuccessionModal, setOpenLifeSuccessionModal] = useState(false);
  const [formInfos, setFormInfos] = useState<IPostOrderIp>({
    isClient: true,
    segment,
    leadId,
    leadType,
    name: initialFormData?.name,
    email: initialFormData?.email,
    phone: initialFormData?.phone,
  } as IPostOrderIp);

  const [disabledInput, setDisabledInput] = useState(false);
  const [isOpenScheduleMeetingModal, setIsOpenScheduleMeetingModal] =
    useState<boolean>(false);
  const [clientCheckbox, setClientCheckbox] = useState<boolean>(false);

  const [meetingDate, setMeetingDate] = useState<string>('');
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>('');

  const { data: employeeWithIPPermissions } = useQuery<
    {
      id: string;
      name: string;
    }[]
  >({
    queryKey: ['employeeWithIPPermissions'],
    queryFn: async () => {
      const response = await hubApiIp.listUsersWithIpPermissions();
      return response;
    },
  });

  const onBlurClient = async (event: React.FocusEvent<HTMLInputElement>) =>
    handleBlurCLient(event, setFormInfos, formInfos);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({
      ...formInfos,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!handleInputDisabled(formInfos.name)) {
        setIsLoading(false);
        return toast.dark('Escreva o nome completo do cliente');
      }

      if (!formInfos.information) {
        setIsLoading(false);
        return toast.dark('Escreva alguma informação');
      }

      if (title === 'IP') {
        return setIsOpenScheduleMeetingModal(true);
      }

      if (subSegment) {
        await hubApiIp.postOrderIp({
          ...formInfos,
          phone: formInfos.phone.replace(/\D/g, ''),
          name: formatNameToCaptalize(formInfos.name),
          subSegment,
        });
      } else {
        await hubApiIp.postOrderIp({
          ...formInfos,
          phone: formInfos.phone.replace(/\D/g, ''),
          name: formInfos.name,
        });
      }

      if (title === 'IP') {
        setIsLoading(false);
        return setOpenLifeSuccessionModal(true);
      }

      setIsLoading(false);
      handleShowModal();
      toast.dark('Ordem enviada com sucesso!');
    } catch (error) {
      if (error.response.status === 409) {
        setIsLoading(false);

        return toast.dark(
          'Já existe uma ordem deste segmento para este cliente'
        );
      }

      toast.dark(
        'Não foi possível enviar a ordem, tente novamente em alguns minutos'
      );
      setIsLoading(false);
    }
  };

  const handleInputDisabled = (name: string) => {
    const data = name;

    if (formInfos.isClient) {
      const verifyData = data.split(' ');
      if (verifyData[1]) {
        setDisabledInput(true);

        return true;
      }
      if (!verifyData[1]) {
        setDisabledInput(false);
        return false;
      }
    } else {
      return true;
    }
  };

  const handleScheduleMeeting = async (
    shouldScheduleMeeting: boolean,
    event?: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      event?.preventDefault();

      if (shouldScheduleMeeting) {
        setIsLoading(true);
      } else {
        setIsLoadingSecondaryButton(true);
      }

      if (shouldScheduleMeeting && !meetingDate) {
        setIsLoading(false);
        setIsLoadingSecondaryButton(false);

        return toast.dark('Selecione uma data para a reunião');
      }

      if (shouldScheduleMeeting && !selectedEmployeeId) {
        setIsLoading(false);
        setIsLoadingSecondaryButton(false);

        return toast.dark('Selecione um colaborador.');
      }

      let order;
      if (subSegment) {
        order = await hubApiIp.postOrderIp({
          ...formInfos,
          phone: formInfos.phone.replace(/\D/g, ''),
          name: formatNameToCaptalize(formInfos.name),
          subSegment,
          transferred: !!shouldScheduleMeeting,
        });
      } else {
        order = await hubApiIp.postOrderIp({
          ...formInfos,
          phone: formInfos.phone.replace(/\D/g, ''),
          name: formInfos.name,
          transferred: !!shouldScheduleMeeting,
        });
      }

      if (shouldScheduleMeeting && order) {
        await hubApiIp.createIPMeeting({
          orderId: order.id,
          status: 'callDialed',
          date: meetingDate,
          segment: 'life',
          employeeId: selectedEmployeeId,
        });
      }

      if (leadId) {
        await hubApiCaptation.createEventsCaptation({
          employeeId: user.id,
          employeeType: 'AAI',
          eventType: 'CREATE_PJ2_IP_ORDER',
          leadId,
          status: 'ok',
        });

        await queryClient.invalidateQueries(['leadHistory', leadId]);
        await queryClient.invalidateQueries(['ipOrders', leadId]);
      }

      setIsLoading(false);
      setIsLoadingSecondaryButton(false);
      setOpenLifeSuccessionModal(true);

      toast.dark('Ordem enviada com sucesso!');
    } catch (error) {
      setIsLoading(false);
      setIsLoadingSecondaryButton(false);

      const err = error as AxiosError;

      if (err.response?.status === 409) {
        return toast.dark('Uma ordem com esse email já existe.');
      }

      if (err.response?.status && err.response?.status > 500) {
        return toast.dark('Aconteceu um erro no servidor. Contacte o suporte!');
      }

      toast.dark(
        'Aconteceu um erro ao tentar criar uma ordem. Tente novamente mais tarde'
      );
    }
  };

  useEffect(() => {
    setFormInfos({ ...formInfos, isClient: !clientCheckbox });
  }, [clientCheckbox]);

  useEffect(() => {
    setDisabledInput(formInfos.isClient);
  }, [formInfos.isClient]);

  return (
    <>
      <BaseModalFormCard
        isModal
        maxW={['95%', '800px', '95%', '800px']}
        title={title}
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={handleShowModal}
        isLoading={isLoading}
        isLoadingSecondaryButton={isLoadingSecondaryButton}
        primaryButtonText="Solicitar"
        secondaryButtonText="Cancelar"
      >
        <Flex gridGap={10} flexDirection={['column', 'row']}>
          <Flex
            flex="1"
            flexDirection="column"
            overflowY={['auto', 'unset']}
            maxH={['200px', '600px']}
          >
            {description !== '' && <Text>{description}</Text>}
          </Flex>

          <Flex
            flex="1"
            flexDirection="column"
            overflowY={['auto', 'unset']}
            maxH={['200px', '600px']}
          >
            <Flex justifyContent="flex-end" mt={-8}>
              <Checkbox
                size="sm"
                onChange={() => setClientCheckbox(!clientCheckbox)}
                isChecked={clientCheckbox}
              >
                Não é cliente EWZ
              </Checkbox>
            </Flex>

            <Stack spacing="3">
              {!clientCheckbox && (
                <InputWithLabel
                  label="Código do cliente:"
                  placeholder="Código BTG do cliente"
                  name="clientCode"
                  onBlur={e => onBlurClient(e)}
                  width={['95%', '100%']}
                />
              )}
              <InputWithLabel
                label="Nome:"
                type="text"
                name="name"
                placeholder="Nome do Cliente"
                value={formInfos.name}
                onChange={handleChangeValue}
                isDisabled={disabledInput}
                isRequired
                width={['95%', '100%']}
              />
              <InputWithLabel
                label="E-mail:"
                type="text"
                name="email"
                placeholder="E-mail do Cliente"
                value={formInfos.email}
                onChange={handleChangeValue}
                isRequired
                width={['95%', '100%']}
              />
              <InputWithLabel
                label="Telefone:"
                type="text"
                name="phone"
                placeholder="Telefone do Cliente"
                value={formInfos.phone && formatToPhone(formInfos.phone)}
                onChange={handleChangeValue}
                isRequired
                width={['95%', '100%']}
              />
              <TextareaWithLabel
                label="Informações:"
                placeholder="Informações relevantes"
                name="information"
                value={formInfos.information}
                onChange={handleChangeValue}
                isRequired
                width={['95%', '100%']}
              />
            </Stack>
          </Flex>
        </Flex>
      </BaseModalFormCard>
      <ModalWrapper isOpen={openTakeOffLead} onClose={() => {}}>
        <DefaultModalContentWrapper width="400px" height="180px">
          <Flex
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
            color="rgba(70, 70, 70, 1)"
          >
            <Text textAlign="center" fontWeight="bold" fontSize="20px">
              Deseja retirar o lead do fluxo de captação?
            </Text>
            <Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap={2}
            >
              <SecondaryButton
                onClick={() => {
                  setOpenTakeOffLead(false);
                  handleShowModal();
                }}
              >
                Não
              </SecondaryButton>
              <PrimaryButton
                onClick={takeOffLeadAction}
                isLoading={isLoadingTakeOffLead}
              >
                Sim
              </PrimaryButton>
            </Flex>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenScheduleMeetingModal}
        onClose={() => setIsOpenScheduleMeetingModal(false)}
      >
        <BaseModalFormCard
          title="Marcar Reunião"
          primaryButtonText="Marcar reunião"
          secondaryButtonText="Continuar sem marcar reunião"
          isLoading={isLoading}
          isLoadingSecondaryButton={isLoadingSecondaryButton}
          secondaryButtonEventFunction={() => handleScheduleMeeting(false)}
          handleFormSubmit={event => handleScheduleMeeting(true, event)}
          handleToggleModal={() => setIsOpenScheduleMeetingModal(false)}
          isModal
        >
          <DateAndHourPicker
            onChange={date => setMeetingDate(date)}
            label="Data da reunião"
          />
          <Dropdown
            mt="5"
            placeholder="Selecione um colaborador"
            onChange={event => setSelectedEmployeeId(event.target.value)}
          >
            {employeeWithIPPermissions &&
              employeeWithIPPermissions.map((collaborator, index: number) => (
                <option key={index} value={collaborator.id}>
                  {formatNameToCaptalize(collaborator.name)}
                </option>
              ))}
          </Dropdown>
        </BaseModalFormCard>
      </ModalWrapper>
      <ModalWrapper isOpen={openLifeSuccessionModal} onClose={() => {}}>
        <DefaultModalContentWrapper width="400px" height="180px">
          <Flex
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
            color="rgba(70, 70, 70, 1)"
          >
            <Text textAlign="center" fontWeight="bold" fontSize="20px">
              Sua ordem foi enviada com sucesso!
            </Text>
            <Text textAlign="center" fontSize="md">
              Agora você só precisa agendar um horário para a reunião desse
              cliente clicando nesse link
            </Text>
            <Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap={2}
            >
              <Link
                isExternal
                href={
                  segment === 'vida'
                    ? 'https://linktr.ee/segurodevidaewz1'
                    : 'https://calendly.com/fernando-ramalho'
                }
              >
                <PrimaryButton
                  onClick={() => {
                    setOpenLifeSuccessionModal(false);
                    // handleShowModal();
                    if (takeOffLeadAction) {
                      setIsLoading(false);
                      setOpenTakeOffLead(true);
                    }
                    toast.dark('Ordem enviada com sucesso!');
                  }}
                >
                  Agendar horário
                </PrimaryButton>
              </Link>
              <SecondaryButton
                onClick={() => {
                  setOpenLifeSuccessionModal(false);
                  // handleShowModal();
                  if (takeOffLeadAction) {
                    setIsLoading(false);
                    setOpenTakeOffLead(true);
                  }
                }}
              >
                Fechar
              </SecondaryButton>
            </Flex>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
