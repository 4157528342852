import { Divider, Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import { ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsBasicData: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { getParam } = useQueryParams();

  const isOnlyVisualizationMode =
    getParam('isOnlyVisualizationMode') === 'true';

  const { mutateAsync: updateClient, isLoading: isLoadingUpdateClient } =
    useUpdateClient({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
      },
    });

  const data = [
    {
      prop: 'name',
      label: 'Nome',
      value: TextUtils.toCapitalize(client.name) || '-',
      editable: !isOnlyVisualizationMode,
    },
    {
      prop: 'btgCode',
      label: 'Código BTG',
      value: client.btgCode || '-',
      editable: false,
    },
    {
      prop: 'email',
      label: 'Email',
      value: client.email || '-',
      editable: false,
    },
    {
      prop: 'phone',
      label: 'Telefone',
      value: client.phone || '-',
      editable: !isOnlyVisualizationMode,
    },
    {
      prop: 'occupation',
      label: 'Profissão',
      value: client.occupation || '-',
      editable: false,
    },
    {
      prop: 'type',
      label: 'Tipo',
      value: client.type || '-',
      editable: false,
    },
    {
      prop: 'city',
      label: 'Cidade',
      value: client.city || '-',
      editable: false,
    },
    {
      prop: 'birthDate',
      label: 'Aniversário',
      value: DateUtils.format(client.birthDate, 'dd/MM/yyyy') || '-',
      editable: false,
    },
    {
      prop: 'origin',
      label: 'Origem',
      value: client.origin || '-',
      editable: false,
    },
    {
      prop: 'cpf',
      label: 'CPF',
      value: client.cpf || '-',
      editable: !isOnlyVisualizationMode,
    },
    {
      prop: 'openAccountDate',
      label: 'Data de Entrada na EWZ',
      value: DateUtils.format(client.openAccountDate, 'dd/MM/yyyy') || '-',
      editable: false,
    },
  ];

  return (
    <Flex flexDirection="column" gap={2} w="100%" p={2}>
      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
        Perfil do Cliente
      </Text>
      <Divider />
      {data.map((item, index) => {
        if (item.editable) {
          return (
            <Flex key={index} gap={1} alignItems="center">
              <Text fontSize="sm" color="#999999">
                {item.label}
              </Text>

              <Input.Root style={{ flex: 1 }}>
                <Input.EditableTextField
                  value={item.value}
                  isLoading={isLoadingUpdateClient}
                  onConfirmEdit={newText =>
                    updateClient({
                      ...client,
                      [item.prop]: newText,
                    })
                  }
                  textProps={{
                    maxW: '240px',
                    isTruncated: true,
                  }}
                />
              </Input.Root>
            </Flex>
          );
        }

        return (
          <Flex key={index} gap={1}>
            <Text fontSize="sm" color="#999999">
              {item.label}
            </Text>
            <Text
              fontSize="sm"
              color="rgba(70, 70, 70, 1)"
              isTruncated
              maxW="320px"
            >
              {item.value}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
