import { Flex, Text } from '@chakra-ui/react';
import { useScroll } from 'hooksV2/useScroll';
import { useMeetingsPoolContext } from 'pagesV2/MeetingsPool/context/useMeetingsPoolContext';
import { GetMeetingsPoolResponseType } from 'services/hubApi/classes/HubApiCaptation';
import { MeetingsPoolMosaicCard } from '../mosaicCard';

type PropsType = {
  meetings: GetMeetingsPoolResponseType | undefined;
  visualizationMode: string;
};

export const MeetingsPoolMosaic: React.FC<PropsType> = ({
  meetings,
  visualizationMode,
}): JSX.Element => {
  const { selectedTab, selectedTabTitle } = useMeetingsPoolContext();
  const { scrollContainerRef } = useScroll();

  const meetingsFiltered =
    meetings &&
    meetings[selectedTab as keyof GetMeetingsPoolResponseType]?.meetings;

  return (
    <Flex
      borderRadius={5}
      flexDirection="column"
      justifyContent="start"
      alignItems="start"
      paddingX="1rem"
      border="1px solid gray"
      h="100%"
      w="100%"
    >
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" mt={4} ml="0.5px">
        {selectedTabTitle}
      </Text>
      {meetingsFiltered?.length === 0 && (
        <Flex mt={4} w="100%" justifyContent="center">
          <Text fontStyle="italic">Sem leads nesta seção</Text>
        </Flex>
      )}
      <Flex
        gap={3}
        mt={4}
        w="100%"
        overflowY="auto"
        wrap="wrap"
        maxH="calc(100vh - 230px)"
        className="thinScrollbar scrollContainer"
        ref={scrollContainerRef}
      >
        {meetingsFiltered &&
          meetingsFiltered.map((meeting, index) => (
            <MeetingsPoolMosaicCard
              key={index}
              meeting={meeting}
              visualizationMode={visualizationMode}
              isInLateSection={false} // Deprecated
            />
          ))}
      </Flex>
    </Flex>
  );
};
