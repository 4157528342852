import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { CardDashSH } from 'pages/GenerationalVariables/components/elements/cards/CardDashSh';
import { IInfosSH } from 'pages/GenerationalVariables/interfaces';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';

import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import formatValue from 'utils/formatValue';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';

interface ISumOfValues {
  leadsOk: number;
  rMarked: number;
  rRouter: number;
  plRouter: number;
  leadsToday: number;
}

export const MosaicDiarySh: React.FC = () => {
  const [infoUsers, setInfoUsers] = useState<IInfosSH[]>([]);
  const [loading, setLoading] = useState(false);
  const [sumOfValues, setSumOfValues] = useState({} as ISumOfValues);
  const [dateFilter, setDateFilter] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const handleGetInfo = async () => {
    setLoading(true);
    try {
      let leadsOk = 0;
      let rMarked = 0;
      let rRouter = 0;
      let plRouter = 0;
      let leadsToday = 0;

      const response: IInfosSH[] = await hubApiCaptation.getDiarySh(dateFilter);

      for (let i = 0; i < response.length; i++) {
        leadsToday += Number(response[i].NEWLEAD);
        leadsOk += Number(response[i].leadsOk);
        rMarked += Number(response[i].MEETCREATED);
        rRouter += Number(response[i].MEETCONFIRMED);
        plRouter += Number(response[i].MEETPL);
      }

      setSumOfValues({
        leadsOk,
        rMarked,
        rRouter,
        plRouter,
        leadsToday,
      });
      setInfoUsers(response);
      setLoading(false);
    } catch (err) {
      toast.dark('Erro ao buscar informações');
      setLoading(false);
    }
  };

  const handleStopSh = async (sh: IInfosSH) => {
    try {
      await hubApiCaptation.patchStopSH(sh.employeeId);

      if (sh.status === 'active') {
        sh.status = 'inative';
      } else {
        sh.status = 'active';
      }

      const shIndex = infoUsers.findIndex(
        infoUser => infoUser.employeeId === sh.employeeId
      );

      setInfoUsers(
        infoUsers.map((item, key) => {
          let shTest = item;

          if (shIndex === key) {
            shTest = sh;
          }
          return shTest;
        })
      );
    } catch (err) {
      toast.dark(
        'Ocorreu um erro ao atualizar as informações, por favor tente novamente mais tarde!'
      );
    }
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  const handlePickDate = (event: ChangeEvent<HTMLInputElement>) => {
    setDateFilter(event.target.value);
  };

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      handleGetInfo();
    }
  }, [lastAuth, dateFilter]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Dashboard Diário SH</MainPageTitle>

      {loading && <LoadingSpinner />}

      <Flex
        w="100%"
        maxW="1024px"
        align="center"
        justify="center"
        mb="3"
        gridGap={4}
        overflowX={['auto', 'unset']}
      >
        {/* <Flex
          height={['100%', 'auto']}
          border="2px solid"
          borderColor="gray.300"
          px="1.5"
          py="0.5"
          borderRadius="4px"
        >
          Leads OK: {sumOfValues.leadsOk || 0}
        </Flex>

        <Flex
          height={['100%', 'auto']}
          border="2px solid"
          borderColor="gray.300"
          px="1.5"
          py="0.5"
          borderRadius="4px"
        >
          Leads Hoje: {sumOfValues.leadsToday || 0}
        </Flex>

        <Flex
          height={['100%', 'auto']}
          border="2px solid"
          borderColor="gray.300"
          px="1.5"
          py="0.5"
          borderRadius="4px"
        >
          R. Marcadas: {sumOfValues.rMarked || 0}
        </Flex>

        <Flex
          height={['100%', 'auto']}
          border="2px solid"
          borderColor="gray.300"
          px="1.5"
          py="0.5"
          borderRadius="4px"
        >
          R. roteadas: {sumOfValues.rRouter || 0}
        </Flex>

        <Flex
          height={['100%', 'auto']}
          border="2px solid"
          borderColor="gray.300"
          px="1.5"
          py="0.5"
          borderRadius="4px"
        >
          Pl Roteado: {formatValue(sumOfValues.plRouter || 0)}
        </Flex>

        <Flex alignSelf="end">
          <DatePickerChackra
            handlePickDate={handlePickDate}
            value={dateFilter}
          />
        </Flex> */}
      </Flex>

      <Flex
        w="100%"
        maxW="1024px"
        overflowY="auto"
        maxH="calc(100vh - 220px)"
        p="1"
        gridGap="4"
        flexWrap="wrap"
      >
        {infoUsers.map(infoUser => {
          // ids literais para não exibir os shs externos da OT Investimoento (B2B Mundo Invest)
          return (
            infoUser.name !== null &&
            infoUser.employeeId !== '5bb4372c-021b-4cb7-b457-fc95f6c506e8' &&
            infoUser.employeeId !== 'd2511648-aa8c-4467-8c30-a08bfd9fa767' && (
              <CardDashSH
                key={infoUser.employeeId}
                infoUser={infoUser}
                handleStopSh={handleStopSh}
              />
            )
          );
        })}
      </Flex>
    </DefaultPageWrapper>
  );
};
