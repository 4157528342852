/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { IMeetingsInfoFromMI } from 'pages/Captation/components/modules/meetings/MeetingsInfosFromMI';
import { DeletePowerBIAccessMutationRequestType } from 'pages/Dashboards/components/employeeWithAccessCard';
import { DeletePowerBIMutationRequestType } from 'pages/Dashboards/components/powerBIManagementCard';
import { CreatePowerBIMutationRequestType } from 'pages/Dashboards/modals/AddPowerBIModal';
import { UpdatePowerBIMutationRequestType } from 'pages/Dashboards/modals/EditPowerBI';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { TokenType } from 'sharedV2/types/token';

interface IEvents {
  eventName: string;
  eventDateAndHour: string;
  description: string;
  eventType: string;
  link?: string;
  participants: string[];
}

export type AccessBIType = {
  id: string;
  employeeId: string;
  parentPowerBIId: string;
  employee: {
    id: string;
    name: string;
    email: string;
  };
};

export type BIType = {
  id: string;
  name: string;
  description: string;
  reportId: string;
  embedUrl: string;
  pageName: string;
  powerBIName: string;
  accessBi: AccessBIType[];
};

export type GetPowerBIDashboardsType = {
  dashboards: { powerBIName: string; pages: BIType[] }[];
};

export type GetPowerBILoadInformationType = {
  accessToken: string;
  page: BIType;
};

export type GetPowerBIManagementType = {
  dashboards: BIType[];
};

export type IPOrderType = {
  id: string;
  employeeId: string;
  leadId: string | null;
  leadType: string | null;
  employee: {
    id: string;
    name: string;
    email: string;
  };
  phone: string;
  name: string;
  email: string;
  value: string;
  information: string;
  reason: string;
  segment: string;
  transferred: boolean;
  finished: boolean;
  boleted: boolean;
  boletValue: number | null;
  description: string;
  reasonNotBolet: string;
  clientCode: string | null;
  isClient: boolean;
  others: string | null;
  nextContact: string | null;
  annualizedPremium: number;
  created_at: string;
  updated_at: string;
};

export type ResetPasswordRequestType = {
  tokenId: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type IPMeetingType = {
  id: string;
  orderId: string;
  status: string;
  date: Date;
  created_at: Date;
  updated_at: Date;
  order: IPOrderType;
};

export type GetIPMeetingsResponseType = {
  title: string;
  name: string;
  meetings: IPMeetingType[];
};

export type UpdateIPMeetingRequestType = {
  meetingId: string;
  status?: string;
  date?: string;
  declinedReason?: string;
  stageBackReason?: string;
  annualizedPremium?: number;
};

export class HubApi {
  baseURL;

  axios;

  constructor() {
    this.baseURL = process.env.REACT_APP_LINK_API;

    this.axios = axios.create({
      baseURL: this.baseURL,
      // baseURL: 'https://aijy1zk0j8.execute-api.sa-east-1.amazonaws.com/v1',
    });
  }

  setHeaders(token: string): void {
    this.axios.defaults.headers.Authorization = `Bearer ${token}`;
  }

  async login(email: string, password: string) {
    const response = await this.axios.post('/identifier/login', {
      email,
      password,
    });
    return response;
  }

  async sendAvatar(formData: FormData, id: string): Promise<void> {
    await this.axios.patch(`employees/avatar/${id}`, formData);
  }

  async submitNewEmployeeForm(formData: any) {
    const response = await this.axios.post(`employees`, formData);
    return response.data;
  }

  async sendEmailToNewEmployee(employeeEmail: string): Promise<void> {
    await this.axios.post('employees/contract', {
      email: employeeEmail,
    });
  }

  async isTokenValid(tokenId: string): Promise<TokenType> {
    const response = await this.axios.get(
      `/identifier/token/valid?id=${tokenId}`
    );

    return response.data;
  }

  async requestResetPassword(email: string): Promise<void> {
    await this.axios.post('/identifier/password/recovery', {
      email,
    });
  }

  async resetPassword(data: ResetPasswordRequestType): Promise<void> {
    await this.axios.post('/identifier/password/reset', data);
  }

  async getEmployeeInfos(employeeId: string) {
    const response = await this.axios.get(`/employees/${employeeId}`);
    return response.data;
  }

  async denieEmployeeFormByManager(employeeFormId: string): Promise<void> {
    await this.axios.patch(`manager/employee/nothired/${employeeFormId}`);
  }

  async sendAcceptedEmployeeForm(
    employeeForm: any,
    employeeId: string
  ): Promise<void> {
    const employeeData = {
      updateEmployeeId: employeeId,
      updateEmployeeData: employeeForm.employeeData,
      permissions: employeeForm.permissions,
    };

    await this.axios.put('manager/send/employee/rh', employeeData);
  }

  async sendRhFeedbackOfEmployeeForm(
    employeeData: any,
    employeeId: string
  ): Promise<void> {
    await this.axios.put(`employees/approve/${employeeId}`, employeeData);
  }

  async getSectorTasks(sector: string, taskType: string, status: string) {
    const response = await this.axios.get('tasks', {
      params: {
        type: taskType,
        sector,
        status,
      },
    });

    return response.data;
  }

  async getRhTasks() {
    const response = await this.axios.get('/rh/tasks');

    return response.data;
  }

  async listEmployees() {
    const response = await this.axios.get(`/employees/managers/table`);
    return response.data;
  }

  async updateEmployeeInfoByManager(employeeId: string, employeeData: string) {
    const res = await this.axios.put(`employees/${employeeId}`, employeeData);
    return res;
  }

  async filterEmployees(fieldName: string, fieldValue: string) {
    const response = await this.axios.get(
      `/employees?fieldName=${fieldName}&fieldValue=${fieldValue}`
    );
    return response.data;
  }

  async listEmployeeClients() {
    const response = await this.axios.get('/clients/me');
    return response.data;
  }

  async getClientInfoForTable(page: string) {
    const response = await this.axios.get(`/clients/table?page=${page}`);
    return response.data;
  }

  async searchForClientCode(clientCode: string) {
    const response = await this.axios.get(`/clients/${clientCode}`);
    return response.data;
  }

  async getClientsFromArea({
    employeeId,
    data,
  }: {
    employeeId: string;
    data: {
      areas: { permission_cod: string; area_cod: string }[];
      isGeral: boolean;
    };
  }) {
    const response = await this.axios.post(
      `orders/clientsArea?employeeId=${employeeId}`,
      data
    );
    return response.data;
  }

  // events

  async getAllEvents() {
    const response = await this.axios.get(`/events`);
    return response.data;
  }

  async postCreateEvent(data: IEvents) {
    const response = await this.axios.post(`/events`, data);
    return response.data;
  }

  async patchEditEvent(eventId: string, data: IEvents) {
    const response = await this.axios.patch(`/events/${eventId}`, data);
    return response.data;
  }

  async deleteEvent(eventId: string) {
    const response = await this.axios.delete(`/events/${eventId}`);
    return response.data;
  }

  async getMeetingInfoFromMI(leadEmail: string) {
    const response = await this.axios.get<{ clients: IMeetingsInfoFromMI[] }>(
      `/mi/clients/search?field=email&value=${leadEmail}&page=1`
    );
    return response.data;
  }

  async deletePowerBIPage(data: DeletePowerBIMutationRequestType) {
    const response = await this.axios.delete(
      `/identifier/bis?id=${data.powerBIId}`
    );
    return response.data;
  }

  async getAllPowerBIs(data: { name?: string }) {
    const response = await this.axios.get<BIType[]>(
      `/identifier/bis${data.name ? `?name=${data.name}` : ''}`
    );
    return response.data;
  }

  async getPowerBIDashboards(data: { reportId?: string; pageName?: string }) {
    let url = `/identifier/bis/dashboards`;

    if (data.reportId) {
      url += `?reportId=${data.reportId}`;
    }

    if (data.pageName) {
      url += `&pageName=${data.pageName}`;
    }

    const response = await this.axios.get<GetPowerBIDashboardsType>(url);
    return response.data;
  }

  async getPowerBILoadInformation(data: {
    reportId: string;
    pageName: string;
  }) {
    const response = await this.axios.get<GetPowerBILoadInformationType>(
      `/identifier/bis/loadInformation?reportId=${data.reportId}&pageName=${data.pageName}`
    );
    return response.data;
  }

  async getPowerBIManagement() {
    const response = await this.axios.get<GetPowerBIManagementType>(
      `/identifier/bis/management`
    );
    return response.data;
  }

  async updatePowerBI(data: UpdatePowerBIMutationRequestType) {
    let url = `/identifier/bis?id=${data.id}&description=${data.description}`;

    if (data.powerBIUrl) url += `&powerBIUrl=${data.powerBIUrl}`;

    const response = await this.axios.patch(encodeURI(url));
    return response.data;
  }

  async createPowerBI(data: CreatePowerBIMutationRequestType) {
    const response = await this.axios.post(`/identifier/bis`, data);
    return response.data;
  }

  async createFakeData({
    thingToCreate,
    location,
  }: {
    thingToCreate: string;
    location: string;
  }) {
    const response = await this.axios.post(`/fake/${thingToCreate}`, {
      location,
    });
    return response.data;
  }

  async getAccessBI(data?: { biId?: string }) {
    let url = '/identifier/accessBis';

    if (data?.biId) {
      url += `?biId=${data.biId}`;
    }
    const response = await this.axios.get(url);
    return response.data;
  }

  async createAccessBI(data: {
    employeeId: string;
    biId: string;
    parentPowerBIId: string;
  }) {
    const response = await this.axios.post(`/identifier/accessBis`, data);
    return response.data;
  }

  async deleteAccessBI(data: DeletePowerBIAccessMutationRequestType) {
    const response = await this.axios.delete('/identifier/accessBis', {
      params: data,
    });
    return response.data;
  }

  async getIPMeetings(data?: { employeeId?: string }) {
    let url = '/orders/ip/meetings';

    if (data?.employeeId) {
      url += `?employeeCRM=${data.employeeId}`;
    }

    const response = await this.axios.get<GetIPMeetingsResponseType[]>(url);
    return response.data;
  }

  async updateIPMeeting(data: UpdateIPMeetingRequestType) {
    const response = await this.axios.patch(
      `/orders/ip/meetings/${data.meetingId}`,
      data
    );
    return response.data;
  }
}

export default new HubApi();
