import {
  ClientEmployeeTag,
  ClientHistoryType,
  ClientMeetingType,
  ClientNotesType,
  ClientPendencyType,
  ClientRemindersType,
  ClientType,
  ClientTypeWithEmployeeName,
} from 'sharedV2/types/client';
import { HubApi } from '../index';

export type CreateAdditionalInformationRequestType = {
  btgCode: string;
  question: string;
  answer: string;
};

export type CreateReminderRequestType = {
  btgCode: string;
  title: string;
  description: string;
  date: string;
};

export type CreateNoteRequestType = {
  btgCode: string;
  content: string;
};

export type CreateMeetingRequestType = {
  btgCode: string;
  date: string;
};

export type CreateAportRequestType = {
  btgCode: string;
  value: number;
  description: string;
  date: string;
};

export enum ClientMeetingActionEnum {
  CONCLUDED = 'concluded',
}

export type MeetingActionRequestType = {
  id: string;
  action: ClientMeetingActionEnum;
  contactType?: string;
  clientSatisfaction?: number;
};

export enum AportActionEnum {
  CONCLUDED = 'concluded',
}

export type AportActionRequestType = {
  action: AportActionEnum;
  id: string;
};

export type UpdateAportRequestType = {
  id: string;
  value: number;
  description: string;
  date: string | Date;
};

class HubApiClients extends HubApi {
  async getMyClients() {
    const response = await this.axios.get<ClientType[]>(
      `/ms-client/v1/clients/own`
    );
    return response.data;
  }

  async getClientHistory(btgCode: string) {
    const response = await this.axios.get<
      (ClientHistoryType | ClientNotesType)[]
    >(`/ms-client/v1/clients/${btgCode}/history`);
    return response.data;
  }

  async getAllClients(data: { filterValue: string }) {
    const response = await this.axios.get<ClientTypeWithEmployeeName[]>(
      `/ms-client/v1/clients?filterValue=${data.filterValue}`
    );
    return response.data;
  }

  async getClientByBtgCode(btgCode: string) {
    const response = await this.axios.get<ClientType>(
      `/ms-client/v1/clients/${btgCode}`
    );
    return response.data;
  }

  async updateClient(client: ClientType) {
    const response = await this.axios.patch(
      `/ms-client/v1/clients/${client.btgCode}`,
      {
        ...client,
      }
    );
    return response.data;
  }

  async createAdditionalInformation(
    data: CreateAdditionalInformationRequestType
  ) {
    const response = await this.axios.post(
      `/ms-client/v1/additionalInformations/${data.btgCode}`,
      {
        question: data.question,
        answer: data.answer,
      }
    );
    return response.data;
  }

  async deleteAdditionalInformation(id: string) {
    const response = await this.axios.delete(
      `/ms-client/v1/additionalInformations/${id}`
    );
    return response.data;
  }

  async createReminder(data: CreateReminderRequestType) {
    const response = await this.axios.post(
      `/ms-client/v1/reminders/${data.btgCode}`,
      {
        title: data.title,
        description: data.description,
        date: data.date,
      }
    );
    return response.data;
  }

  async updateReminder(data: ClientRemindersType) {
    const response = await this.axios.patch(
      `/ms-client/v1/reminders/${data.id}`,
      {
        title: data.title,
        description: data.description,
        date: data.date,
      }
    );
    return response.data;
  }

  async deleteReminder(reminderId: string) {
    const response = await this.axios.delete(
      `/ms-client/v1/reminders/${reminderId}`
    );
    return response.data;
  }

  async createNote(data: CreateNoteRequestType) {
    const response = await this.axios.post(
      `/ms-client/v1/notes/${data.btgCode}`,
      {
        content: data.content,
      }
    );
    return response.data;
  }

  async createMeeting(data: CreateMeetingRequestType) {
    const response = await this.axios.post(
      `/ms-client/v1/meetings/${data.btgCode}`,
      {
        date: data.date,
      }
    );
    return response.data;
  }

  async updateMeeting(data: ClientMeetingType) {
    const response = await this.axios.patch(
      `/ms-client/v1/meetings/${data.id}`,
      {
        commentedAreas: data.commentedAreas,
        observations: data.observations,
      }
    );
    return response.data;
  }

  async deleteMeeting(id: string) {
    const response = await this.axios.delete(`/ms-client/v1/meetings/${id}`);
    return response.data;
  }

  async getMeetingById(meetingId: string) {
    const response = await this.axios.get<ClientMeetingType>(
      `/ms-client/v1/meetings/${meetingId}`
    );
    return response.data;
  }

  async meetingAction(data: MeetingActionRequestType) {
    const response = await this.axios.patch(
      `/ms-client/v1/meetings/${data.id}/${data.action}`,
      {
        ...data,
      }
    );
    return response.data;
  }

  async getEmployeeTags() {
    const response = await this.axios.get<ClientEmployeeTag[]>(
      '/ms-client/v1/tags'
    );
    return response.data;
  }

  async createTag(tag: string) {
    const response = await this.axios.post(`/ms-client/v1/tags/`, {
      name: tag,
    });
    return response.data;
  }

  async createAport(data: CreateAportRequestType) {
    const response = await this.axios.post(
      `/ms-client/v1/aports/${data.btgCode}`,
      data
    );
    return response.data;
  }

  async aportAction(data: AportActionRequestType) {
    const response = await this.axios.patch(
      `/ms-client/v1/aports/${data.id}/${data.action}`
    );
    return response.data;
  }

  async updateAport(data: UpdateAportRequestType) {
    const response = await this.axios.patch(
      `/ms-client/v1/aports/${data.id}`,
      data
    );
    return response.data;
  }

  async deleteAport(id: string) {
    const response = await this.axios.delete(`/ms-client/v1/aports/${id}`);
    return response.data;
  }

  async getEmployeesHistory(btgCode: string) {
    const response = await this.axios.get<
      { employeeName: string; startDate: string; endDate: string }[]
    >(`/ms-client/v1/clients/${btgCode}/employees/history`);
    return response.data;
  }

  async getPendencies() {
    const response = await this.axios.get<ClientPendencyType[]>(
      `/ms-client/v1/pendencies`
    );
    return response.data;
  }

  async deletePendency(pendencyId: string) {
    const response = await this.axios.delete(
      `/ms-client/v1/pendencies/${pendencyId}`
    );
    return response.data;
  }
}

export const hubApiClients = new HubApiClients();
