import { Badge, Flex, Text } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { ModalWrapper } from 'components/elements/wrappers';
import { PrimaryButton } from 'componentsV2/elements/buttons/primary';
import { Menu } from 'componentsV2/elements/menu';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import {
  BasicForm,
  RealEstateAndFleetForm,
} from 'pages/IP/components/modules/forms';
import { ipProducts } from 'pages/IP/data';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { formatNameToCaptalize } from 'utils/captalizeNames';

function UserIcon() {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.339 8.661c4.882 4.882 4.882 12.796 0 17.678-4.882 4.881-12.796 4.881-17.678 0-4.881-4.882-4.881-12.796 0-17.678 4.882-4.881 12.796-4.881 17.678 0"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M8.472 25.873c2.543-2.604 5.288-4.206 9.065-4.206 3.74 0 6.448 1.57 8.99 4.126C23.986 28.398 21.24 30 17.463 30c-3.74 0-6.447-1.57-8.99-4.127z"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.446 10.387a4.167 4.167 0 11-5.892 5.893 4.167 4.167 0 015.892-5.893"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const LeadDetailsHeader = (): JSX.Element => {
  const { lead, desiredFlow } = useLeadDetailsContext();

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  const { data: ipOrders } = useQuery<IPOrderType[]>({
    queryKey: ['ipOrders', lead.id],
    queryFn: async () => {
      const response = await hubApiIp.getOrderIpByLeadId(lead.id);
      return response;
    },
  });

  const thereIsAIpOrderActive =
    ipOrders &&
    ipOrders.filter(
      order => order.finished === false && order.segment === 'vida'
    ).length > 0;

  const thereIsAAPOrderActive =
    ipOrders &&
    ipOrders.filter(
      order => order.finished === false && order.segment === 'imobiliario'
    ).length > 0;

  return (
    <Flex w="100%" alignItems="center" gap={2} justifyContent="space-between">
      <Flex>
        <UserIcon />
        <Text
          fontSize="xl"
          color="rgba(70, 70, 70, 1)"
          fontWeight="bold"
          isTruncated
          maxW="900px"
        >
          {formatNameToCaptalize((lead?.razaoSocial || lead.name) ?? '-')}
        </Text>
        {lead.EWZClient && (
          <Text fontSize="lg" fontStyle="italic" color="#1D63DDB2">
            - Cliente EWZ
          </Text>
        )}
      </Flex>
      {!isSalesHunterDesiredFlow && (
        <Flex gap={2} alignItems="center">
          {(thereIsAIpOrderActive || thereIsAAPOrderActive) && (
            <Flex
              bgColor="#EDEDED"
              borderRadius={5}
              borderBottom="2px solid rgba(159, 159, 159, 0.698)"
              w="fit-content"
              px={2}
              py={1}
            >
              <Text color="rgba(70, 70, 70, 1)" fontSize="0.8rem">
                Lead indicado para PJ2
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};
