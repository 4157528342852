import { Box, Flex, Switch, Text } from '@chakra-ui/react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { formatToCapitalized } from 'brazilian-values';

import { IInfosSH } from 'pages/GenerationalVariables/interfaces';
import history from 'services/history';
import formatValue from 'utils/formatValue';

interface CardDashProps {
  infoUser: IInfosSH;
  handleStopSh: (sh: IInfosSH) => Promise<void>;
}

export const CardDashSH: React.FC<CardDashProps> = ({
  infoUser,
  handleStopSh,
}) => {
  const currencyDate = new Date().toISOString().slice(0, 7);

  const handleNextPage = () => {
    history.push(
      `/generatinal_variables/shNumbers/${infoUser.employeeId}/${currencyDate}`
    );
  };

  const waitigng =
    Number(infoUser.MEETCONFIRMED) -
    Number(infoUser.MEETSUCCESSFUL) -
    Number(infoUser.MEETUNSUCCESSFUL);

  const taskPending = Number(infoUser.leadsOk) * 2 - Number(infoUser.held);

  return (
    <Box
      bg="white"
      py="2"
      px="3"
      borderRadius="2"
      border="2px solid gray"
      maxH="262px"
      w="100%"
      maxW="240px"
      cursor="pointer"
      _hover={{
        bg: '#f2f2f2',
      }}
      transition="all 0.2s ease-in"
    >
      <Flex w="100%" justify="space-between" align="center" gridGap={3}>
        <Text
          fontWeight="bold"
          fontSize="md"
          color="rgba(70, 70, 70, 1)"
          mb="1.5"
          isTruncated
        >
          {formatToCapitalized(infoUser.name, {
            trimTrailingWhiteSpaces: false,
          })}
        </Text>

        {/* <Switch
          isChecked={infoUser.status === 'active'}
          onChange={() => handleStopSh(infoUser)}
        /> */}
      </Flex>

      <Flex flexDir="column" onClick={handleNextPage}>
        <Text fontSize="smaller">
          Status:
          <Text as="span" color="rgba(70, 70, 70, 1)">
            {' '}
            {infoUser.status === 'hired' ? 'Contratado' : 'Descontinuado'}
          </Text>
        </Text>
        {/* <Text fontSize="smaller">
          QTD. de leads mosaico:
          <Text as="span" color="white">
            {' '}
            {infoUser.leads}
          </Text>
        </Text>

        <Text fontSize="smaller">
          QTD. de leads ok:
          <Text as="span" color="white">
            {' '}
            {infoUser.leadsOk}
          </Text>
        </Text>

        <Text fontSize="smaller">
          Leads OK PL:
          <Text as="span" color="white">
            {' '}
            {!infoUser.leadsOkPL || Number(infoUser.leadsOkPL) === 0
              ? formatValue('0')
              : formatValue(infoUser.leadsOkPL)}
          </Text>
        </Text>

        <Text fontSize="smaller">
          QTD. de leads hoje:
          <Text as="span" color="white">
            {' '}
            {infoUser.NEWLEAD}
          </Text>
        </Text>

        <Text fontSize="smaller">
          Reuniões marcadas:
          <Text as="span" color="white">
            {' '}
            {infoUser.MEETCREATED}
          </Text>
        </Text>
        <Text fontSize="smaller">
          Retornos:
          <Text as="span" color="white">
            {' '}
            {infoUser.CALLBACK}
          </Text>
        </Text>

        <Flex justify="space-between">
          <Text fontSize="smaller">
            RNA:
            <Text as="span" color="white">
              {' '}
              {infoUser.MEETUNSUCCESSFUL}
            </Text>
          </Text>

          <Flex gridGap="1">
            <FaRegCalendarAlt size={16} color="#5CE046" />
            <Text minW="24px" color="white" fontSize="smaller">
              {infoUser.status === 'inactive'
                ? '-'
                : Number(infoUser.held) > 0
                ? infoUser.held
                : 0}
            </Text>
          </Flex>
        </Flex>

        <Flex justify="space-between">
          <Text fontSize="smaller">
            Aguardando:
            <Text as="span" color="white">
              {' '}
              {waitigng > 0 ? waitigng : 0}
            </Text>
          </Text>

          <Flex gridGap="1" justify="end">
            <FaRegCalendarAlt size={16} color="#FF5E5E" />
            <Text minW="24px" color="white" fontSize="smaller">
              {infoUser.status === 'inactive'
                ? '-'
                : taskPending > 0
                ? taskPending
                : 0}
            </Text>
          </Flex>
        </Flex>

        <Text fontSize="smaller">
          RA:
          <Text as="span" color="white">
            {' '}
            {infoUser.MEETSUCCESSFUL}
          </Text>
        </Text>

        <Text fontSize="smaller">
          PL Roteado:
          <Text as="span" color="white">
            {' '}
            {formatValue(infoUser.MEETPL)}
          </Text>
        </Text>

        <Text fontSize="smaller">
          Reuniões roteadas:
          <Text as="span" color="white">
            {' '}
            {infoUser.MEETCONFIRMED}
          </Text>
        </Text> */}
      </Flex>
    </Box>
  );
};
