import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { ClientType } from 'sharedV2/types/client';
import parser from 'html-react-parser';
import { formateDateAndTime } from 'utils/formatDate';
import { useQuery } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import {
  CalendarPlus,
  Chat,
  CheckCircle,
  CurrencyCircleDollar,
  NotePencil,
  PhoneIncoming,
  PhoneOutgoing,
  Question,
} from 'phosphor-react';

type PropsType = {
  client: ClientType;
  history:
    | {
        employeeName: string;
        startDate: string;
        endDate: string;
      }[]
    | undefined;
};

export const ClientDetailsLastNotes: React.FC<PropsType> = ({
  client,
  history,
}): JSX.Element => {
  const { data: clientHistory } = useQuery({
    queryKey: ['clientHistory', client.btgCode],
    queryFn: async () => {
      const response = await hubApiClients.getClientHistory(client.btgCode);
      return response;
    },
  });

  const handleGetActionTranslated = (action: string): string => {
    const obj: Record<string, string> = {
      MEETING_CREATED: 'Reunião agendada',
      APORT_CREATED: 'Aporte agendado',
      REMINDER_CREATED: 'Lembrete criado',
      MEETING_CONCLUDED: 'Reunião concluída',
      CONTACT_FREQUENCY_ACTIVATED: 'Frequência de contato ativada',
      CONTACT_FREQUENCY_DEACTIVATED: 'Frequência de contato desativada',
    };

    return obj[action] || '?';
  };

  const handleGetIcon = (action: string) => {
    const obj: Record<string, JSX.Element> = {
      'Reunião agendada': <CalendarPlus size={30} color="#1D63DDB2" />,
      'Aporte agendado': <CurrencyCircleDollar size={30} color="#1D63DDB2" />,
      'Lembrete criado': <NotePencil size={30} color="#1D63DDB2" />,
      'Reunião concluída': <CheckCircle size={30} color="#1D63DDB2" />,
      'Frequência de contato ativada': (
        <PhoneOutgoing size={30} color="#1D63DDB2" />
      ),
      'Frequência de contato desativada': (
        <PhoneIncoming size={30} color="#1D63DDB2" />
      ),
    };

    return (
      obj[handleGetActionTranslated(action)] || (
        <Question size={30} color="#1D63DDB2" />
      )
    );
  };

  return (
    <Flex
      h="100%"
      border="1px solid #D6D6D6"
      borderRadius="5px"
      maxH={`calc(${(history?.length || 0) * 69}px - 16px + 680px)`}
      p={2}
      flexDirection="column"
      gap={2}
      w="100%"
    >
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Histórico de Atividades
      </Text>

      <Divider />

      <Flex
        flexDirection="column"
        gap={0}
        overflowY="auto"
        className="thinScrollbar"
      >
        {clientHistory?.length === 0 && (
          <Text color="#999999" textAlign="center" fontStyle="italic">
            Nenhuma atividade até o momento
          </Text>
        )}

        {clientHistory &&
          clientHistory.map(item => {
            if ('content' in item) {
              return (
                <Box key={item.id} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(item.createdAt)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.content)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            return (
              <Box key={item.id} width="100%">
                <Flex gap={3} alignItems="center">
                  <Flex flexDirection="column" alignItems="center">
                    {handleGetIcon(item.action)}

                    <Flex border="1px solid #b0b0b0" h="45px" />
                  </Flex>

                  <Flex flexDirection="column">
                    <Text fontSize="sm" mt={-6}>
                      {formateDateAndTime(item.createdAt)}
                    </Text>
                    <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                      {handleGetActionTranslated(item.action)}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};
