import { Checkbox, Flex } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { Button } from 'componentsV2/elements/buttons';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { Input } from 'componentsV2/elements/input';
import { Menu } from 'componentsV2/elements/menu';
import { Tooltip } from 'componentsV2/elements/tooltip';
import {
  CommonBreakPointsEnum,
  useCommonMediaQueries,
} from 'hooksV2/useCommonMediaQueries';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { VisualizationModeEnum } from 'hooksV2/useVisualizationMode';
import { SortLeadsProspectEnum } from 'pagesV2/LeadsProspectPool/hooks/useFilterLeadsProspect';
import { useState } from 'react';
import { colors } from 'sharedV2/colors';
import { Icons } from 'sharedV2/icons';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  handleChangeFilterLeadsByContactAttempts: (value: string) => void;
  filterLeadsByContactAttempts: string;
  handleChangeVisualizationMode: (value: VisualizationModeEnum) => void;
  handleSortLeadsProspect: (value: SortLeadsProspectEnum) => void;
  visualizationModeValue: VisualizationModeEnum;
  sortLeadsProspectValue: SortLeadsProspectEnum;
  handleChangeFilterLeadsByNameOrPhoneOrListName: (value: string) => void;
  filterLeadsByNameOrPhoneOrListNameValue: string;
  handleChangeFilterLeadsByType: (value: string) => void;
  filterLeadsByType: string;
  shouldFetchDeclinedLeads: string;
  handleChangeShouldFetchDeclinedLeads: (value: string) => void;
};

function FunnelX() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={38}
      viewBox="0 0 256 256"
    >
      <path d="M227.82 66.76A16 16 0 00216 40H40a16 16 0 00-11.81 26.76l.08.09L96 139.17V216a16 16 0 0024.87 13.32l32-21.34a16 16 0 007.13-13.32v-55.49l67.73-72.32zM40 56zm106.19 74.59A8 8 0 00144 136v58.66L112 216v-80a8 8 0 00-2.16-5.46L40 56h176zm99.49 79.81a8 8 0 01-11.32 11.32L216 203.32l-18.34 18.35a8 8 0 01-11.31-11.32L204.69 192l-18.34-18.35a8 8 0 0111.31-11.31L216 180.69l18.34-18.34a8 8 0 0111.32 11.31L227.31 192z" />
    </svg>
  );
}

export const LeadsProspectFilters: React.FC<PropsType> = ({
  handleChangeFilterLeadsByContactAttempts,
  handleChangeVisualizationMode,
  visualizationModeValue,
  handleSortLeadsProspect,
  filterLeadsByContactAttempts,
  sortLeadsProspectValue,
  handleChangeFilterLeadsByNameOrPhoneOrListName,
  filterLeadsByNameOrPhoneOrListNameValue,
  filterLeadsByType,
  handleChangeFilterLeadsByType,
  handleChangeShouldFetchDeclinedLeads,
  shouldFetchDeclinedLeads,
}): JSX.Element => {
  const breakPoint = useCommonMediaQueries();
  const { setParam, deleteParam } = useQueryParams();
  const { redirect } = useNavigation();

  const [
    filterLeadsByNameOrPhoneOrListName,
    setFilterLeadsByNameOrPhoneOrListName,
  ] = useState<string>(filterLeadsByNameOrPhoneOrListNameValue || '');

  const handleSearchLeadsByNameOrPhoneOrListName = async () => {
    if (!filterLeadsByNameOrPhoneOrListName)
      return showToast('Digite algum filtro');

    handleChangeFilterLeadsByNameOrPhoneOrListName(
      filterLeadsByNameOrPhoneOrListName
    );
  };

  const handleClearSearchLeads = async () => {
    handleChangeFilterLeadsByNameOrPhoneOrListName('');
    setFilterLeadsByNameOrPhoneOrListName('');
  };

  const handleClearFilters = () => {
    handleClearSearchLeads();
    deleteParam([
      'filterLeadsByContactAttempts',
      'filterLeadsByType',
      'filterLeadsByNameOrPhoneOrListNameValue',
      'sortLeadsProspectBy',
    ]);
  };

  return (
    <Flex flexDirection="column">
      <Flex w="100%" justifyContent="flex-end">
        <Checkbox
          defaultChecked={shouldFetchDeclinedLeads === 'true'}
          checked={shouldFetchDeclinedLeads === 'true'}
          onChange={() =>
            handleChangeShouldFetchDeclinedLeads(
              shouldFetchDeclinedLeads === 'true' ? '' : 'true'
            )
          }
          borderColor="rgba(70, 70, 70, 1)"
          color="rgba(70, 70, 70, 1)"
        >
          Leads declinados
        </Checkbox>
      </Flex>
      <Flex w="100%" gap={3} justifyContent="space-between">
        <Flex gap={2} w="50rem">
          <Tooltip text="Limpar filtros">
            <button type="button" onClick={handleClearFilters}>
              <FunnelX />
            </button>
          </Tooltip>

          <Dropdown.Root
            w="50%"
            onChange={event =>
              handleChangeFilterLeadsByContactAttempts(event.target.value)
            }
          >
            <Dropdown.Options
              options={[
                { label: '0', value: '0' },
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5 ou mais', value: '5plus' },
              ]}
              selected={filterLeadsByContactAttempts}
              placeholder="Tentativas de Contato"
              optionWithoutValueName="Todas"
            />
          </Dropdown.Root>

          <Dropdown.Root
            w="50%"
            onChange={event =>
              handleSortLeadsProspect(
                event.target.value as SortLeadsProspectEnum
              )
            }
          >
            <Dropdown.Options
              options={[
                { label: 'Nome (A - Z)', value: SortLeadsProspectEnum.NAME },
                {
                  label: 'Últimos Contatos ↓',
                  value: SortLeadsProspectEnum.LAST_CONTACT_ATTEMPTS_DESC,
                },
                {
                  label: 'Últimos Contatos ↑',
                  value: SortLeadsProspectEnum.LAST_CONTACT_ATTEMPTS_ASC,
                },
              ]}
              selected={sortLeadsProspectValue}
              placeholder="Ordenação"
              optionWithoutValueName="Padrão"
            />
          </Dropdown.Root>

          <Dropdown.Root
            w="50%"
            onChange={event =>
              handleChangeFilterLeadsByType(event.target.value)
            }
          >
            <Dropdown.Options
              options={[
                { label: 'Mundo Invest', value: LeadTypeEnum.MUNDO_INVEST },
                {
                  label: 'Manual',
                  value: LeadTypeEnum.MANUAL,
                },
                {
                  label: 'Lista',
                  value: 'list',
                },
                {
                  label: 'Minerado',
                  value: 'mined',
                },
              ]}
              selected={filterLeadsByType}
              placeholder="Origem"
              optionWithoutValueName="Todos"
            />
          </Dropdown.Root>
        </Flex>

        <Flex gap={2}>
          <Input.Root w="210px">
            <Input.TextField
              style={{ flex: 1 }}
              value={filterLeadsByNameOrPhoneOrListName}
              onChange={event => {
                if (event.target.value.length === 0)
                  return handleClearSearchLeads();

                setFilterLeadsByNameOrPhoneOrListName(event.target.value);
              }}
              placeholder="Nome, Número ou Lista"
              rightElement={{
                icon: Icons.X,
                onClick: handleClearSearchLeads,
              }}
            />
          </Input.Root>

          <Button.Primary
            h="100%"
            onClick={handleSearchLeadsByNameOrPhoneOrListName}
            isLoading={false}
          >
            Pesquisar leads
          </Button.Primary>
          <SecondaryButton
            h="100%"
            onClick={() => redirect('/v2/captation/mundoInvest/pool')}
            isLoading={false}
          >
            Pool MI
          </SecondaryButton>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="flex-end"
          w={breakPoint === CommonBreakPointsEnum.SMALL ? 'none' : '30rem'}
        >
          <Menu.Root>
            <Menu.Button>
              <Icons.USER_PLUS
                size={32}
                onClick={() => setParam('isOpenAddNewLeadModal', 'true')}
                cursor="pointer"
              />
            </Menu.Button>
            <Menu.List>
              <Menu.Item
                onClick={() => setParam('isOpenAddNewLeadManualModal', 'true')}
              >
                Criar lead manual
              </Menu.Item>
              <Menu.Item onClick={() => redirect('/captation/upload_leads')}>
                Criar lead lista/minerado
              </Menu.Item>
            </Menu.List>
          </Menu.Root>
          <Flex>
            <Icons.LIST
              size={32}
              color={
                visualizationModeValue === VisualizationModeEnum.TABLE
                  ? colors.BLUE
                  : colors.WHITE
              }
              onClick={() =>
                handleChangeVisualizationMode(VisualizationModeEnum.TABLE)
              }
            />
            <Icons.GRID
              size={32}
              color={
                visualizationModeValue === VisualizationModeEnum.MOSAIC
                  ? colors.BLUE
                  : colors.WHITE
              }
              onClick={() =>
                handleChangeVisualizationMode(VisualizationModeEnum.MOSAIC)
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
